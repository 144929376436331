.barra::-webkit-scrollbar{
    width: 10px;
    background: black;
}

.barra::-webkit-scrollbar-thumb{
    
    background: red;
    border-radius: 5px;
    opacity: 0.5;
}

body::-webkit-scrollbar{

    width: 7px;
    
}

body::-webkit-scrollbar-thumb{

    background: black;
}

.buttonB {
    border: none;
    text-align: left;
  }
  

  @media (min-width : 992px) {
    .offcanvas {
      visibility      : visible;
      position        : relative;
      background      : none;
      border          : none;
      justify-content : end;
      color           : red;
    }
  }
  
  @media (max-width : 992px) {
    .offcanvas {
      width : 50% !important;
    }
    .offcanvas-start-lg {
      top          : 0;
      left         : 0;
      border-right : 1px solid rgba(0, 0, 0, .2);
      transform    : translateX(-100%);
    }
  }
  
  